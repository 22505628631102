<template>
  <section id="card-navigation">
    <b-row>
      <b-col md="12">
        <b-overlay
          rounded="sm"
          spinner-variant="primary"
        >
          <b-card class="text-center">
            <b-card-header class="justify-content-between p-0 pb-1 mb-1 border-bottom">
              <section class="d-flex align-items-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.back()"
                >
                  <feather-icon
                    class="mr-25"
                    icon="ChevronLeftIcon"
                  />
                </b-button>
                <h3 class="mb-0 ml-2 d-flex justify-space-between">
                  <span>{{ program.title }}</span>
                </h3>
              </section>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="justify-content-end p-1 flatpickr-button"
                variant="outline-primary"
              >
                <flat-pickr
                  v-model="beginDate"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
                  class="custom-flatpickr"
                  placeholder="Calender"
                />
              </b-button>
            </b-card-header>
            <b-tabs
              v-model="activeTab"
              nav-class="mb-3"
              active
              pills
              class="pb-2"
              @input="updateHash"
            >
              <b-tab
                class="text-left"
                href="#Modules"
                lazy
                title="Modules"
              >
                <module-manager />
              </b-tab>
              <b-tab
                href="#Workshops"
                lazy
                title="Workshops"
              >
                <b-card
                  class="text-left"
                  title="Manage Workshop"
                >
                  <workshop-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="#MasterClass"
                lazy
                title="Masterclass"
              >
                <b-card
                  class="text-left"
                  title="Manage Masterclass"
                >
                  <master-class-manager />
                </b-card>
              </b-tab>
              <b-tab
                class="text-left"
                href="#E-Meet"
                lazy
                title="Sessions"
              >
                <b-card
                  class="text-left"
                  title="Meeting Manager"
                >
                  <emeet-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="#KnowledgeBase"
                lazy
                title="Library"
              >
                <b-card
                  class="text-left"
                  title="Library"
                >
                  <knowledge-base />
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import flatPickr from 'vue-flatpickr-component'
import WorkshopManager from './WorkshopManager.vue'
import MasterClassManager from './MasterClassManager.vue'
import KnowledgeBase from './KnowledgeBase.vue'
import ModuleManager from './ModuleManager.vue'

export default {
  components: {
    WorkshopManager,
    ModuleManager,
    MasterClassManager,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    BButton,
    flatPickr,
    KnowledgeBase,
  },
  data() {
    return {
      program: '',
      activeTab: 0,
      tabs: [
        '#Modules',
        '#Workshops',
        '#MasterClass',
        '#Sessions',
        '#Library'],
    }
  },
  watch: {
    // Watch for changes in route, activate corresponding tab if hash is changed
    $route: {
      handler(to, from) {
        if (to.hash !== from.hash) {
          this.activeTab = this.tabs.findIndex(tab => tab === to.hash)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query: gql`
      query Manage($id:Int!) {
        programs_basicinfo_by_pk(id:$id){
          id
          title
          begin_date
        }
      }`,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-flatpickr {
  border: none !important;
  padding: 1px !important;
  width: 10px;
  min-width: 100px;
  text-align: start;
}

.custom-flatpickr input {
  padding: 0 !important;
  width: 100%;
}
</style>
